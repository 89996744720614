import { Column, paddingUtil } from "@gadgetinc/widgets";
import { AuthLightTheme, AuthMarketingTheme } from "@gadgetinc/widgets/src/AuthTheme";
import { ThemeProvider, useStyletron } from "baseui";
import { Heading, HeadingLevel } from "baseui/heading";
import type { ReactNode } from "react";
import React from "react";
import { Helmet } from "react-helmet";
import { Breakpoint } from "./AuthLayout";

export const AUTH_BOX_MAX_WIDTH = "412px";

export const AuthBox = (props: {
  children: React.ReactNode;
  pageTitle: string;
  heading?: ReactNode;
  headingStyleLevel?: number;
  subHeading?: ReactNode;
  subHeadingStyleLevel?: number;
  isPreLogin?: boolean;
  isLeftAligned?: boolean;
  maxWidth?: string;
  minWidth?: string;
  isTopAligned?: boolean;
  backgroundColor?: string;
}) => {
  const [_, $theme] = useStyletron();
  const heading = props.heading ?? props.pageTitle;

  const textAlign = props.isPreLogin || props.isLeftAligned ? "left" : "center";

  return (
    <ThemeProvider theme={props.isPreLogin ? AuthMarketingTheme : AuthLightTheme}>
      <Column
        $style={{
          ...paddingUtil($theme.sizing.scale900, $theme.sizing.scale1600, $theme.sizing.scale1600),
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          backgroundColor: props.backgroundColor,
          [Breakpoint.Large]: paddingUtil($theme.sizing.scale1000, $theme.sizing.scale1600, $theme.sizing.scale1600),
          [Breakpoint.Medium]: paddingUtil($theme.sizing.scale1000, $theme.sizing.scale1200, $theme.sizing.scale1200),
          [Breakpoint.Small]: paddingUtil($theme.sizing.scale1000, $theme.sizing.scale900, $theme.sizing.scale900),
        }}
      >
        <Column
          $style={{
            flex: 1,
            alignItems: "center",
            justifyContent: props.isTopAligned ? "start" : "center",
            paddingBottom: $theme.sizing.scale300,
            minHeight: 0,
            maxWidth: props.maxWidth ?? AUTH_BOX_MAX_WIDTH,
            minWidth: props.minWidth,
            width: "100%",
          }}
        >
          <Helmet>
            <title>{props.pageTitle}</title>
          </Helmet>
          <HeadingLevel>
            {heading && (
              <Column $as="header" $gap={$theme.sizing.scale600} $style={{ marginBottom: $theme.sizing.scale900, width: "100%" }}>
                <Heading styleLevel={props.headingStyleLevel} $style={{ textAlign }}>
                  {heading}
                </Heading>
                {props.subHeading && typeof props.subHeading === "string" && (
                  <Heading styleLevel={props.subHeadingStyleLevel} $style={{ width: "100%" }}>
                    {props.subHeading}
                  </Heading>
                )}
                {props.subHeading && typeof props.subHeading !== "string" && props.subHeading}
              </Column>
            )}
            {props.children}
          </HeadingLevel>
        </Column>
      </Column>
    </ThemeProvider>
  );
};
