import { StyledSvg } from "baseui/icon";
import React from "react";

export const CardboardBoxIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M4.63477 2C4.19016 2 3.7674 2.19774 3.48242 2.53971L2.34766 3.90169H2.34701C2.123 4.17109 2 4.51117 2 4.86198V12.1667C2 13.1733 2.82674 14 3.83333 14H12.1667C13.1733 14 14 13.1733 14 12.1667V4.86198C14 4.51117 13.8769 4.17125 13.6523 3.90169L12.5176 2.53971C12.2326 2.19774 11.8098 2 11.3652 2H4.63477ZM4.63477 3H7.5V4.33333H3.28906L4.25065 3.17969C4.34568 3.06566 4.48604 3 4.63477 3ZM8.5 3H11.3652C11.514 3 11.6543 3.06566 11.7493 3.17969L12.7103 4.33333H8.5V3ZM3 5.33333H13V12.1667C13 12.6327 12.6327 13 12.1667 13H3.83333C3.36726 13 3 12.6327 3 12.1667V5.33333ZM6.5 6.66667C6.43374 6.66573 6.36796 6.67797 6.30648 6.70268C6.24499 6.72738 6.18903 6.76406 6.14185 6.81059C6.09466 6.85711 6.0572 6.91255 6.03162 6.97367C6.00605 7.0348 5.99288 7.1004 5.99288 7.16667C5.99288 7.23293 6.00605 7.29853 6.03162 7.35966C6.0572 7.42079 6.09466 7.47622 6.14185 7.52275C6.18903 7.56927 6.24499 7.60595 6.30648 7.63066C6.36796 7.65536 6.43374 7.6676 6.5 7.66667H9.5C9.56626 7.6676 9.63204 7.65536 9.69352 7.63066C9.75501 7.60595 9.81097 7.56927 9.85815 7.52275C9.90534 7.47622 9.9428 7.42079 9.96838 7.35966C9.99395 7.29853 10.0071 7.23293 10.0071 7.16667C10.0071 7.1004 9.99395 7.0348 9.96838 6.97367C9.9428 6.91255 9.90534 6.85711 9.85815 6.81059C9.81097 6.76406 9.75501 6.72738 9.69352 6.70268C9.63204 6.67797 9.56626 6.66573 9.5 6.66667H6.5Z"
      fill="#545454"
    />
  </StyledSvg>
);

export const CardboardBoxFilledIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.5 4.33333V2H4.635C4.18867 2 3.76867 2.19667 3.48267 2.53967L2.34767 3.90167C2.23967 4.031 2.15667 4.17767 2.098 4.33333H7.5ZM8.5 4.33333H13.902C13.8433 4.17767 13.7603 4.031 13.6523 3.90167L12.5173 2.53967C12.231 2.19667 11.8113 2 11.365 2H8.5V4.33333ZM2 5.33333V12.1667C2 13.1777 2.82267 14 3.83333 14H12.1667C13.1773 14 14 13.1777 14 12.1667V5.33333H2ZM9.5 7.66667H6.5C6.224 7.66667 6 7.443 6 7.16667C6 6.89033 6.224 6.66667 6.5 6.66667H9.5C9.776 6.66667 10 6.89033 10 7.16667C10 7.443 9.776 7.66667 9.5 7.66667Z"
      fill="black"
    />
  </StyledSvg>
);
