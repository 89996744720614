import type { DropdownMenuProps } from "@gadgetinc/widgets";
import { DropdownMenu, Row, expandBorderRadii } from "@gadgetinc/widgets";
import { LogoutIcon } from "@gadgetinc/widgets/src/icons/LogoutIcon";
import { useStyletron } from "baseui";
import { LabelSmall } from "baseui/typography";
import { compact } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import Gravatar from "react-gravatar";
import type { StyleObject } from "styletron-react";

export const UserDropdown = observer(
  (props: {
    userEmail: string;
    $style?: StyleObject;
    fullWidth?: boolean;
    dropdownOverrides?: DropdownMenuProps["overrides"];
    overrideArrowDirection?: "up" | "down";
  }) => {
    const [css, $theme] = useStyletron();
    const rootURL = process.env.NODE_ENV === "production" ? "https://app.gadget.dev" : "https://app.ggt.dev";

    return (
      <Row
        $gap={$theme.sizing.scale200}
        className={css({
          ...props.$style,
        })}
      >
        <DropdownMenu
          fullWidth
          items={compact([
            {
              label: (
                <Row $gap={$theme.sizing.scale400}>
                  <LogoutIcon />
                  Logout
                </Row>
              ),
              onSelect: () => (window.location.href = `${rootURL}/auth/logout`),
            },
          ])}
          overrides={props.dropdownOverrides}
          overrideArrowDirection={props.overrideArrowDirection}
        >
          <Row $gap={$theme.sizing.scale300}>
            <Gravatar
              email={props.userEmail}
              size={16}
              rating="pg"
              default="identicon"
              className={css({
                ...expandBorderRadii("50%"),
              })}
            />
            <LabelSmall
              $style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.userEmail}
            </LabelSmall>
          </Row>
        </DropdownMenu>
      </Row>
    );
  }
);
