import { Body, Row, expandBorderRadii } from "@gadgetinc/widgets";
import { OpenInNewTabIcon } from "@gadgetinc/widgets/src/icons/OpenInNewTabIcon";
import { styled, useStyletron } from "baseui";
import type { ReactNode } from "react";
import React from "react";
import type { StyleObject } from "styletron-react";

export type NavLinkProps = {
  selected: boolean;
  onClick?: (event: React.MouseEvent) => void;
  children: ReactNode;
  icon?: ReactNode;
  endEnhancer?: ReactNode;
  $indent?: boolean;
  href?: string;
  testId?: string;
  draggable?: boolean;
  $style?: StyleObject;
  overrides?: {
    Row?: {
      style: StyleObject;
    };
    Body?: {
      style: StyleObject;
    };
  };
  external?: boolean;
};

export const Link = styled("a", {
  textDecoration: "none",
  cursor: "pointer",
});

export const MenuItem = (props: NavLinkProps) => {
  const [css, $theme] = useStyletron();

  const style = css({
    paddingTop: $theme.sizing.scale100,
    paddingBottom: $theme.sizing.scale100,
    paddingLeft: props.$indent ? $theme.sizing.scale600 : $theme.sizing.scale300,
    paddingRight: $theme.sizing.scale300,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    outlineOffset: "-2px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    outlineColor: $theme.colors.contentAccent,
    backgroundColor: props.selected ? $theme.colors.themedAlpha100 : undefined,
    ":hover": {
      backgroundColor: props.selected ? $theme.colors.themedAlpha100 : $theme.colors.themedAlpha50,
    },
    ...expandBorderRadii($theme.borders.radius300),
    color: props.selected ? $theme.colors.black : "#545454",
    ...props.$style,
  });

  return (
    <Link
      href={props.href}
      onClick={props.onClick}
      className={style}
      data-testid={props.testId}
      draggable={!!props.draggable}
      target={props.external ? "_blank" : undefined}
    >
      <Row $gap={$theme.sizing.scale300} $style={{ height: "20px", ...props.overrides?.Row?.style }}>
        {props.icon}
        <Body $style={{ marginTop: "1px", fontWeight: props.selected ? 600 : 500, ...props.overrides?.Body?.style }}>{props.children}</Body>
      </Row>

      {props.endEnhancer ?? (props.external ? <OpenInNewTabIcon /> : undefined)}
    </Link>
  );
};
