import { StyledSvg } from "baseui/icon";
import React from "react";

export const CircleDollarIcon = (props: React.ComponentProps<typeof StyledSvg> & { color?: string }) => {
  return (
    <StyledSvg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6001 8.09961C13.6001 11.1372 11.1377 13.5996 8.1001 13.5996C5.06253 13.5996 2.6001 11.1372 2.6001 8.09961C2.6001 5.06204 5.06253 2.59961 8.1001 2.59961C11.1377 2.59961 13.6001 5.06204 13.6001 8.09961ZM14.6001 8.09961C14.6001 11.6895 11.6899 14.5996 8.1001 14.5996C4.51025 14.5996 1.6001 11.6895 1.6001 8.09961C1.6001 4.50976 4.51025 1.59961 8.1001 1.59961C11.6899 1.59961 14.6001 4.50976 14.6001 8.09961ZM8.64178 12.4333V11.2725C9.84103 11.1084 10.5593 10.3988 10.5587 9.32518C10.5587 8.37727 10.0301 7.8486 8.80265 7.59023L8.06653 7.43369C7.35153 7.28148 7.05957 7.06535 7.05957 6.68889C7.05957 6.2236 7.48261 5.92731 8.12178 5.92731C8.73928 5.92731 9.18832 6.24852 9.24736 6.73114H10.4493C10.4163 5.79243 9.70128 5.10614 8.64178 4.93443V3.7666H7.55844V4.93606C6.46753 5.11102 5.77744 5.8076 5.77744 6.79885C5.77744 7.72998 6.3229 8.30956 7.43603 8.54248L8.23174 8.71148C8.98086 8.87235 9.28528 9.0966 9.28528 9.48606C9.28528 9.94702 8.81565 10.2688 8.15103 10.2688C7.43169 10.2688 6.93228 9.95135 6.87324 9.45627H5.6377C5.67832 10.4508 6.4047 11.1219 7.55844 11.2757V12.4333H8.64178Z"
        fill={props.color ?? "black"}
      />
    </StyledSvg>
  );
};

export const CircleDollarFilledIcon = (props: React.ComponentProps<typeof StyledSvg> & { color?: string }) => {
  return (
    <StyledSvg viewBox="0 0 16 16" {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.9987 1.33203C4.32272 1.33203 1.33203 4.32272 1.33203 7.9987C1.33203 11.6747 4.32272 14.6654 7.9987 14.6654C11.6747 14.6654 14.6654 11.6747 14.6654 7.9987C14.6654 4.32272 11.6747 1.33203 7.9987 1.33203ZM7.83203 3.66536C8.10836 3.66536 8.33203 3.88903 8.33203 4.16536V4.51302C9.0877 4.58269 9.73488 5.1102 9.93555 5.85286C10.0075 6.11953 9.85 6.39415 9.58333 6.46615C9.31533 6.53715 9.04173 6.38027 8.9694 6.11393C8.87173 5.75193 8.54103 5.4987 8.16536 5.4987H7.58203C7.07636 5.4987 6.66536 5.9097 6.66536 6.41536C6.66536 6.92103 7.07636 7.33203 7.58203 7.33203H8.2487C9.39736 7.33203 10.332 8.2667 10.332 9.41536C10.332 10.536 9.44203 11.4501 8.33203 11.4941V11.832C8.33203 12.1084 8.10836 12.332 7.83203 12.332C7.5557 12.332 7.33203 12.1084 7.33203 11.832V11.4844C6.56936 11.4144 5.92061 10.8806 5.72461 10.1289C5.65494 9.86157 5.8147 9.58855 6.08203 9.51888C6.35036 9.44955 6.62239 9.60897 6.69206 9.8763C6.78772 10.243 7.11871 10.4987 7.49805 10.4987H8.24805C8.84538 10.4987 9.33138 10.0127 9.33138 9.41536C9.33138 8.81803 8.84603 8.33203 8.2487 8.33203H7.58203C6.52536 8.33203 5.66536 7.47203 5.66536 6.41536C5.66536 5.44336 6.39336 4.63993 7.33203 4.51693V4.16536C7.33203 3.88903 7.5557 3.66536 7.83203 3.66536Z"
          fill={props.color ?? "black"}
        />
      </svg>
    </StyledSvg>
  );
};
