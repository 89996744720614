import { Button, ButtonKind, Column, marginHorizontal, paddingHorizontal, paddingVertical } from "@gadgetinc/widgets";
import { AddIcon } from "@gadgetinc/widgets/src/icons/AddIcon";
import { AppsFilledIcon, AppsIcon } from "@gadgetinc/widgets/src/icons/AppsIcon";
import { CardboardBoxFilledIcon, CardboardBoxIcon } from "@gadgetinc/widgets/src/icons/CardboardBoxIcon";
import { CircleDollarFilledIcon, CircleDollarIcon } from "@gadgetinc/widgets/src/icons/CircleDollarIcon";
import { HelpIcon } from "@gadgetinc/widgets/src/icons/HelpIcon";
import { SpeedFilledIcon, SpeedIcon } from "@gadgetinc/widgets/src/icons/SpeedIcon";
import { UserGroupFilledIcon, UserGroupIcon } from "@gadgetinc/widgets/src/icons/UserGroupIcon";
import { useStyletron } from "baseui";
import type { ReactNode } from "react";
import React from "react";
import type { InitialBillingPlanProps, TeamProps } from "state-trees/src/Team";
import { Link, useLocation } from "wouter";
import { MenuItem } from "../edit/workspace/LeftNavBar/MenuItem";
import { TeamDropdown } from "./TeamDropdown";
import { UserDropdown } from "./UserDropdown";

const NavItem = (props: { label: string; icon: ReactNode; iconOnSelected?: ReactNode; href: string; external?: boolean }) => {
  const [_css, $theme] = useStyletron();
  const [location, setLocation] = useLocation();
  const selected = location === props.href;
  const icon = selected ? props.iconOnSelected ?? props.icon : props.icon;

  return (
    <MenuItem
      href={props.href}
      selected={selected}
      onClick={(event) => {
        event.preventDefault();
        if (props.external) {
          window.open(props.href, "_blank");
        } else {
          setLocation(props.href);
        }
      }}
      icon={icon}
      $style={{
        ...paddingVertical($theme.sizing.scale200),
      }}
      external={props.external}
      overrides={{
        Body: {
          style: {
            color: selected ? $theme.colors.primary : $theme.colors.primary600,
          },
        },
      }}
    >
      {props.label}
    </MenuItem>
  );
};

type AuthNavBarProps = {
  hasWideContent?: boolean | string;
  userEmail?: string;
  docsRootURL: string;
} & (
  | {
      team?: undefined;
      otherTeams?: undefined;
      initialPlan?: undefined;
    }
  | {
      team: TeamProps;
      otherTeams: TeamProps[];
      initialPlan: InitialBillingPlanProps;
    }
);

export const AuthLeftNavBar = (props: AuthNavBarProps) => {
  const [_, $theme] = useStyletron();

  return (
    <Column
      $style={{
        height: "100vh",
        borderRight: `1px solid ${$theme.colors.alpha300}`,
        backgroundColor: $theme.colors.primary50,
        ...paddingVertical($theme.sizing.scale500),
      }}
      $align="space-between"
    >
      {/* Top section */}
      <div
        style={{
          width: "260px",
        }}
      >
        <Column $gap={$theme.sizing.scale600}>
          <Column
            $gap={$theme.sizing.scale600}
            $style={{
              ...paddingHorizontal($theme.sizing.scale500),
            }}
          >
            {props.team && <TeamDropdown team={props.team} otherTeams={props.otherTeams} initialPlan={props.initialPlan} />}
            <Link href="/auth/team/create-app?reset=true">
              <Button startEnhancer={<AddIcon />} kind={ButtonKind.secondary}>
                Create app
              </Button>
            </Link>

            <Column>
              <NavItem label="Apps" icon={<AppsIcon />} iconOnSelected={<AppsFilledIcon />} href="/auth/team" />
              <NavItem label="Members" icon={<UserGroupIcon />} iconOnSelected={<UserGroupFilledIcon />} href="/auth/team/members" />
              <NavItem label="Usage" icon={<SpeedIcon />} iconOnSelected={<SpeedFilledIcon />} href="/auth/team/billing#usage" />
              <NavItem
                label="Billing"
                icon={<CircleDollarIcon color="#545454" />}
                iconOnSelected={<CircleDollarFilledIcon />}
                href="/auth/team/billing"
              />
              <NavItem
                label="Plan"
                icon={<CardboardBoxIcon />}
                iconOnSelected={<CardboardBoxFilledIcon />}
                href="/auth/team/billing#plans"
              />
            </Column>
          </Column>

          <hr
            style={{
              border: `1px solid ${$theme.colors.alpha300}`,
              borderBottom: "none",
            }}
          />

          <Column
            $style={{
              ...paddingHorizontal($theme.sizing.scale500),
            }}
          >
            <NavItem label="Guide" icon={<HelpIcon />} external href={`${props.docsRootURL}/guides`} />
          </Column>
        </Column>
      </div>

      {/* Bottom section */}
      {props.userEmail && (
        <div>
          <UserDropdown
            userEmail={props.userEmail}
            fullWidth
            $style={{
              ...marginHorizontal($theme.sizing.scale500),
            }}
            dropdownOverrides={{
              menu: {
                List: {
                  style: {
                    width: "236px",
                    overflow: "auto",
                    boxShadow: "none",
                    boxSizing: "content-box",
                    paddingTop: $theme.sizing.scale200,
                    paddingBottom: $theme.sizing.scale200,
                  },
                },
              },
            }}
            overrideArrowDirection="up"
          />
        </div>
      )}
    </Column>
  );
};
