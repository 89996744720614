import { StyledSvg } from "baseui/icon";
import React from "react";

export const AppsIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M6.16667 8.33152H3.16667C2.52333 8.33152 2 7.80819 2 7.16485V4.16485C2 3.52152 2.52333 2.99819 3.16667 2.99819H6.16667C6.81 2.99819 7.33333 3.52152 7.33333 4.16485V7.16485C7.33333 7.80819 6.81 8.33152 6.16667 8.33152ZM3.16667 3.99819C3.075 3.99819 3 4.07319 3 4.16485V7.16485C3 7.25652 3.075 7.33152 3.16667 7.33152H6.16667C6.25833 7.33152 6.33333 7.25652 6.33333 7.16485V4.16485C6.33333 4.07319 6.25833 3.99819 6.16667 3.99819H3.16667ZM6.16667 14.3315H3.16667C2.52333 14.3315 2 13.8082 2 13.1649V10.1649C2 9.52152 2.52333 8.99819 3.16667 8.99819H6.16667C6.81 8.99819 7.33333 9.52152 7.33333 10.1649V13.1649C7.33333 13.8082 6.81 14.3315 6.16667 14.3315ZM3.16667 9.99819C3.075 9.99819 3 10.0732 3 10.1649V13.1649C3 13.2565 3.075 13.3315 3.16667 13.3315H6.16667C6.25833 13.3315 6.33333 13.2565 6.33333 13.1649V10.1649C6.33333 10.0732 6.25833 9.99819 6.16667 9.99819H3.16667ZM12.1667 14.3315H9.16667C8.52333 14.3315 8 13.8082 8 13.1649V10.1649C8 9.52152 8.52333 8.99819 9.16667 8.99819H12.1667C12.81 8.99819 13.3333 9.52152 13.3333 10.1649V13.1649C13.3333 13.8082 12.81 14.3315 12.1667 14.3315ZM9.16667 9.99819C9.075 9.99819 9 10.0732 9 10.1649V13.1649C9 13.2565 9.075 13.3315 9.16667 13.3315H12.1667C12.2583 13.3315 12.3333 13.2565 12.3333 13.1649V10.1649C12.3333 10.0732 12.2583 9.99819 12.1667 9.99819H9.16667ZM11.3333 8.28552C11.0347 8.28552 10.736 8.17185 10.5083 7.94452L8.387 5.82319C7.93233 5.36852 7.93233 4.62819 8.387 4.17352L10.5083 2.05219C10.9633 1.59719 11.703 1.59719 12.158 2.05219L14.2793 4.17352C14.734 4.62819 14.734 5.36852 14.2793 5.82319L12.158 7.94452C11.9307 8.17185 11.632 8.28552 11.3333 8.28552ZM11.3333 2.71019C11.2907 2.71019 11.248 2.72652 11.2153 2.75885L9.094 4.88019C9.02933 4.94519 9.02933 5.05085 9.094 5.11585L11.2153 7.23719C11.2803 7.30219 11.386 7.30219 11.451 7.23719L13.5723 5.11585C13.637 5.05085 13.637 4.94519 13.5723 4.88019L11.451 2.75885C11.4187 2.72652 11.376 2.71019 11.3333 2.71019Z"
      fill="#545454"
    />
  </StyledSvg>
);

export const AppsFilledIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M6.16667 2.99852H3.16667C2.52333 2.99852 2 3.52185 2 4.16519V7.16519C2 7.80852 2.52333 8.33185 3.16667 8.33185H6.16667C6.81 8.33185 7.33333 7.80852 7.33333 7.16519V4.16519C7.33333 3.52185 6.81 2.99852 6.16667 2.99852ZM6.16667 8.99852H3.16667C2.52333 8.99852 2 9.52185 2 10.1652V13.1652C2 13.8085 2.52333 14.3319 3.16667 14.3319H6.16667C6.81 14.3319 7.33333 13.8085 7.33333 13.1652V10.1652C7.33333 9.52185 6.81 8.99852 6.16667 8.99852ZM12.1667 8.99852H9.16667C8.52333 8.99852 8 9.52185 8 10.1652V13.1652C8 13.8085 8.52333 14.3319 9.16667 14.3319H12.1667C12.81 14.3319 13.3333 13.8085 13.3333 13.1652V10.1652C13.3333 9.52185 12.81 8.99852 12.1667 8.99852ZM14.2797 4.17352L12.1583 2.05219C11.7033 1.59719 10.9637 1.59719 10.5087 2.05219L8.38733 4.17352C7.93267 4.62819 7.93267 5.36852 8.38733 5.82319L10.509 7.94485C10.7363 8.17185 11.0347 8.28519 11.3337 8.28519C11.6323 8.28519 11.931 8.17152 12.1587 7.94452L14.28 5.82319C14.7343 5.36852 14.7343 4.62852 14.2797 4.17352Z"
      fill="black"
    />
  </StyledSvg>
);
